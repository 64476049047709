<template>
  <div class="faq">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader :header="$t('FAQ.TITLE')" />
          <Accordion :items="questions" class="margins__triple" />

          <template>
            <Separator size="large" />

            <h3>{{ $t('FAQ.FAQ_LIST_2_TITLE') }}</h3>
            <Accordion :items="questions2" class="margins__triple" />

            <Separator size="large" />

            <h3>{{ $t('FAQ.FAQ_LIST_3_TITLE') }}</h3>
            <Accordion :items="questions3" class="margins__triple" />
            <Separator size="large" />

            <h3>{{ $t('FAQ.FAQ_LIST_4_TITLE') }}</h3>
            <Accordion :items="questions4" class="margins__triple" />
          </template>
        </Margins>
      </Card>
    </Container>
  </div>
</template>
‌
<script>
import {
  Accordion,
  Card,
  CardHeader,
  Container,
  Margins,
  Separator,
} from '@/components';
import { getDynamicTranslation } from '@/utils';
import { constants } from '@/mixins';

export default {
  name: 'Faq',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    Accordion,
    Separator,
  },
  computed: {
    questions() {
      return getDynamicTranslation(this.$i18n, 'FAQ.FAQ_LIST');
    },
    questions2() {
      return getDynamicTranslation(this.$i18n, 'FAQ.FAQ_LIST_2');
    },
    questions3() {
      return getDynamicTranslation(this.$i18n, 'FAQ.FAQ_LIST_3');
    },
    questions4() {
      return getDynamicTranslation(this.$i18n, 'FAQ.FAQ_LIST_4');
    },
  },
};
</script>
